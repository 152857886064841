<template>
  <el-button :type="type" class="btn-block" @click="toDetail" v-if="event.status == 'DOING'">{{ $t('events.JoinNow') }}</el-button>
  <el-button :type="type" class="btn-block" @click="toDetail" v-else-if="event.status == 'WAITING' && event.reserved">{{ $t('events.EditRSVP') }}</el-button>
  <el-button :type="type" class="btn-block" @click="orderEvent" v-else-if="event.status == 'WAITING'">{{ $t('events.RSVP') }}</el-button>
  <el-button :type="type" class="btn-block" @click="toDetail" v-else-if="from !== 'list'">{{ $t('events.Detail') }}</el-button>
</template>

<script>
import api from '@/api'

export default {
  name: 'EventButton',
  props: {
    event: Object,
    type: {
      type: String,
      default: ''
    },
    from: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      
    }
  },
  methods: {
    toDetail() {
      this.$router.push(`/event/${this.event.id}`)
    },
    orderEvent() {
      api.orderEvent(this.event.id).then(res => {
        this.$message({
          message: this.$t('events.Success'),
          type: 'success'
        })
        this.toDetail()
      })
    }
  }
}
</script>
